<template>
  <div>
    <el-container>
      <el-aside width="300px" :style="'height: ' + asideHeight + 'px'">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="20"
            layout="total, prev, next, jumper"
            :total="total">
        </el-pagination>
        <ul :style="'overflow:auto; height:' + (asideHeight-32) +'px'" class="ul">
          <li v-for="(item, index) in tempList" :key="index" style="" class="li"
              @click="playVideo(item.link, item.title)">
            <el-row style="margin: 0">
              <el-col :span="10">
                <el-image :src="item.img" style="width: 100%; height: 100px" fit="fit"></el-image>
              </el-col>
              <el-col :span="14">
                <p class="Ptitle">{{ item.title }}</p>
              </el-col>
            </el-row>
          </li>
        </ul>
      </el-aside>
      <el-container>
        <el-header>
          <p style="margin: 10px" class="Ptitle">{{ title }}</p>
        </el-header>
        <el-main>
          <div style="width: 70%; text-align: center; padding-left: 15%; padding-top: 20px">
            <div>
              <Artplayer ref="artplayer"/>
            </div>
          </div>
        </el-main>
        <el-footer>
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="watchTogether">一起看</el-button>
            </el-col>
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="downFile" v-if="$store.state.user.userInfo.luAdmin">下载
              </el-button>
            </el-col>
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="jumpPlay">跳转播放</el-button>
            </el-col>
            <el-col :span="4" class="footerDiv">
              <el-button type="button" size="small" @click="drawer = true">打开列表</el-button>
            </el-col>
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="dialogVisible = true">搜索</el-button>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </el-container>
    <el-drawer
        title="播放列表"
        :visible.sync="drawer"
        :direction="direction"
        size="40%"
        custom-class="drawer"
        :before-close="handleClose">
      <section v-if="allListShow">
        <div>
          <ul class="ul1" style="height:95vh;overflow-y:scroll;">
            <li class="li1" v-for="(instance, index) in fileName" :key="index"
                @click="loadFileData(instance, index);">
              <a :class="[listIndex === index ? 'liChoose' : 'liNoChoose']"> <span
                  class="text"> {{ instance.substring(0, instance.lastIndexOf('.json')) }} </span></a>
            </li>
          </ul>
        </div>
      </section>
      <div v-else>
        <el-row>
          <el-col :span="24">
            <el-cascader-panel :options="options" @change="changeTree" ref="panel" :key="cascaderKey"/>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
    <el-dialog
        title="搜索"
        :visible.sync="dialogVisible"
        width="100%"
        :destroy-on-close="true"
        :before-close="handleClose">
      <div style="margin-top: 5px;">
        <el-row type="flex" class="row-bg" justify="space-around" style="margin-bottom: 8px">
          <el-col :span="14">
            <el-radio v-model="searchAll" label="1">搜索全部</el-radio>
            <el-radio v-model="searchAll" label="2">搜索当前</el-radio>
          </el-col>
        </el-row>
        <el-input placeholder="请输入内容" v-model="searchName">
          <el-button slot="append" icon="el-icon-search" @click="searchPv(1)"></el-button>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
  </span>
    </el-dialog>
  </div>
</template>

<script>

import M3u8 from "@/request/expand/av/m3u8";
import cryptoUtil from "@/utils/cryptoUtil";
import RoomAsk from "@/request/expand/tvRoom";
import Artplayer from "../../../components/Artplayer.vue";
import DownFile from "../../../request/expand/downFile";

export default {
  name: "av_pc",
  components: {Artplayer},
  data() {
    return {
      roomAsk: new RoomAsk(),
      askIndex: new M3u8(),
      askDownFile: new DownFile(),
      asideHeight: 0,
      drawer: false,
      dialogVisible: false,
      direction: 'ltr',
      listIndex: 0,
      fileName: [],
      tempList: [],
      dataList: [],
      searchName: "",
      title: "",
      allListShow: false,
      cascaderKey: 101,
      options: [],
      searchAll: "1",
      total: 0,
      currentPage:1
    };
  },
  methods: {
    changeTree(data) {
      this.loadFileData(data.join("/"), 0)
    },
    downFile() {
      let param = {
        "fileName": this.title,
        "fileUrl": this.link,
        "threadCount": 100
      }
      this.askDownFile.addTask(param).then(res => {
        if (res.code === 'success') {
          this.$message({
            showClose: true,
            message: '恭喜你，新增成功',
            type: 'success'
          });
        }
      })
    },
    /**
     * 搜索资源
     */
    searchPv(pageNumber) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.currentPage = pageNumber
      let data = {
        "fieldConditions": [
          {
            "value": `*${this.searchName}*`,
            "should": true,
            "fieldName": "title",
          }
        ],
        "indexCatalog": "pv",
        "pageNumber": pageNumber,
        "pageSize": 20
      }
      if (this.searchAll === '2') {
        try {
          let cascade = this.$refs.panel.getCheckedNodes(true)
          data.fieldConditions.push({
            "value": `${cascade[0].path.join(">")}`,
            "should": true,
            "fieldName": "type.keyword",
          })
        } catch (e) {
          console.log('大橙同学')
        }
      }
      this.askIndex.searchByCondition(data).then(res => {
        try {
          if (res.resolveList == null) {
            this.$message({
              showClose: true,
              message: '没有搜索到资源嗷~',
              type: 'warning'
            });
            return
          }
          this.tempList = res.resolveList
          this.totalPages = (res.total + 20 - 1) / 20;
          this.total = res.total
          // this.tempList = this.dataList.slice(0, 20)
          this.dialogVisible = false;
          this.dataList = []
        } catch (e) {
          this.$message({
            showClose: true,
            message: '错了哦，这是一条错误消息',
            type: 'error'
          });
        } finally {
          loading.close();
        }
      }).catch(e => {
        loading.close();
      })
    },
    handleClose(done) {
      done();
    },
    loadFileData(fileName, index) {
      this.listIndex = index
      this.askIndex.getData(fileName).then(res => {
        this.dataList = res.list
        this.total = res.list.length
        if (res.list != null && res.list.length > 0) {
          this.totalPages = (res.list.length + 20 - 1) / 20;
          this.tempList = this.dataList.slice(0, 20)
        }
        this.drawer = false;
      })
    },
    playVideo(link, title) {
      this.title = title
      this.link = link
      document.title = this.$t('title_') + title
      this.$refs.artplayer.changeUrl(link);
      // this.$refs.artplayer.player.play();
    },
    /**
     * 一起看
     */
    watchTogether() {
      let data = {
        tvrName: "pv_" + this.$store.state.user.userInfo.lUsrName,
        tvrPwd: '123456',
        tvrUrl: this.link
      }
      this.roomAsk.updRoomInfo(data).then(res => {
        if (res.code === 'success') {
          let cryptoName = new cryptoUtil().encryptFun(data.tvrName)
          this.$router.push({
            name: 'tvRoom',
            query: {
              type: "pv",
              tvrName: cryptoName
            }
          })
        }
      })
    },
    /**
     * 跳转播放
     */
    jumpPlay() {
      // this.myPlayer.pause()
      // const {href} = this.$router.resolve({
      //   name: 'm3u8Play',
      // })
      // this.$storageHandler.setLocalStorage("jumpLink", this.link);
      window.open(this.$store.getters["systemConfig/getCommon"].videoPlayerAdd + this.link, '_blank')
    },
    handleCurrentChange(val) {
      if (this.dataList.length > 0) {
        this.tempList = this.dataList.slice(val * 20 - 20, 20 * val - 1)
      } else {
        this.searchPv(val);
      }
      this.$nextTick(function () {
        const e = document.getElementsByClassName('li');
        if (e.length > 0) {
          e[0].scrollIntoView();
        }
      })
    }
  },
  mounted() {
    this.asideHeight = window.innerHeight - 61
    window.onresize = () => {
      this.asideHeight = window.innerHeight - 61
    };
    if (this.allListShow) {
      this.askIndex.getFileNameData().then(res => {
        this.fileName = res
      })
    } else {
      this.askIndex.getCascadeData().then(res => {
        this.options = res
      })
    }
  },
  destroyed() {
    //页面销毁，同时也销毁video.js对象
    // this.myPlayer.dispose();
  }
};
</script>

<style scoped>
/deep/ .el-cascader-panel.is-bordered {
  border: none;
}

/deep/ .el-cascader-menu__wrap {
  height: 90vh;
}

.ul {
  margin: 0;
  padding-left: 0px;
}

.el-header, .el-footer {
  background-color: #c3cfe2;
//color: #333; text-align: center;
}

.el-aside {
//background-color: #D3DCE6; //color: #333; //background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); text-align: center;
}

.el-main {
//background-color: #c3cfe2; //color: #333; background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); text-align: center;
  padding: 0;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.ul1 {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  color: #e6e9f0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 0;
  white-space: nowrap;
}

.li1 {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  color: #e6e9f0;
  list-style: none;
  font-size: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 10px 0 0 0;
  padding: 0;
  vertical-align: top;
  -webkit-transform: translateZ(0);
  /*display: inline-block;*/
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 2px;
  white-space: normal;
  background: hsla(0, 0%, 100%, .08);
}

.li1 > a {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  list-style: none;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: -webkit-box;
  width: 100%;
  height: 100%;
  /*padding: 0 12px;*/
  overflow: hidden;
  border-radius: 2px;
  font-size: 14px;
  line-height: 18px;
  vertical-align: middle;
  border-color: #f25b20;
  color: #f25b20;
}

.li1 > a > span {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  list-style: none;
  white-space: normal;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  color: #f25b20;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
}

.mod_hd {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  /*color: #e6e9f0;*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  position: relative;
  margin: 0 15px 13px;
}

.mod_title {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*color: #e6e9f0;*/
  flex: none;
  max-width: 60%;
  padding-right: 10px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.liChoose {
  background: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}

.liNoChoose {
  background: #202131;
}

.Ptitle {
  margin: 0;
  padding-left: 5px;
  overflow: hidden; /*超出部分隐藏*/
  word-wrap: break-word;
  text-align: left;
//text-overflow: ellipsis; /* 超出部分显示省略号 */ //white-space: nowrap;/*规定段落中的文本不进行换行 */ height: 100px; //border: 1px solid red; //font-size: 30px;
}

.footerDiv {
  margin-top: 15px;
}
</style>
