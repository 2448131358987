import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";
import axios from "axios";


export default class M3u8 {
    constructor() {
        this.http = new HttpHandler();
    }

    //获取数据
    getData(data) {
        // const url = 'http://file.charon520.vip/json/01.json';
        const url = '/api/resources/pv/'
        return this.http.get(url + data, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    getData2() {
        axios.get('http://file.charon520.vip/json/01.json').then(function (response) {
            return response;
        }).catch(function (error) {
            console.log(error);
        });
    }


    getFileNameData() {
        return this.http.get('/api/pv/getDataS', null, HttpConstant.APPLICATION_JSON_REQ);
    }

    getCascadeData() {
        return this.http.get('/api/pv/getCascadeData', null, HttpConstant.APPLICATION_JSON_REQ);
    }

    searchByCondition(data) {
        const url = '/api/pv/film'
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }


    getFileContentData(fileName) {
        return this.http.get('/api/pv/getContent', {'fileName': fileName}, HttpConstant.APPLICATION_JSON_REQ);
    }

    watchRecord(data) {
        const url = '/api/pv/vb'
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }

    out(data) {
        const url = '/api/pv/out'
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }
}