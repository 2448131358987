import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class DownFile {
    constructor() {
        this.http = new HttpHandler();
    }

    getDataS(data) {
        return this.http.post(`/api/fileV/getDataS`, data, HttpConstant.FORM_DATA_REQ);
    }

    addTask(data) {
        return this.http.post(`/api/fileV/addTask`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    deleteTask(data){
        return this.http.post(`/api/fileV/delTask`, data, HttpConstant.FORM_DATA_REQ);
    }

    updateState(data){
        return this.http.post(`/api/fileV/updateState`, data, HttpConstant.FORM_DATA_REQ);
    }

    terminatedTask(data){
        return this.http.post(`/api/fileV/terminatedTask`, data, HttpConstant.FORM_DATA_REQ);
    }


}