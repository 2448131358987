<template>
  <div>
    <mobile v-if="isPhone_var" ref="mobile"/>
    <pc v-else ref="pc"/>
    <div v-if="$store.getters['systemConfig/getGGShow'] && isPhone_var">
      <i class="el-icon-circle-close" style="float: right; margin-top: 5px; font-size: 30px; "
         @click='$store.commit("systemConfig/closeGgShow", false)'></i>
      <iframe frameborder="no" style="pointer-events: none" src="https://file.charonv.com/GG.html" width="100%"
              height="100vh"></iframe>
    </div>
  </div>
</template>

<script>
import {isPhone} from "@/utils/validate";
import mobile from "./mobile.vue"
import pc from "./pc.vue";

export default {
  name: "pvMiddle",
  components: {
    mobile,
    pc
  },
  data() {
    return {
      isPhone_var: true,
    }
  },
  mounted() {

  },
  created() {
    if (!this.$store.state.user.isLogin) {
      this.$message({
        showClose: true,
        message: '错了哦，未登录不可以进入PV喔!',
        type: 'error'
      });
      this.$router.push({path: '/'})
      return;
    }
    this.isPhone_var = isPhone()
  },
  // destroyed() {
  //   if (this.isPhone_var) {
  //     this.$refs.mobile.myPlayer.dispose();
  //   } else {
  //     this.$refs.pc.myPlayer.dispose();
  //   }
  // }
}
</script>

<style scoped>

</style>